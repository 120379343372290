<template>
  <b-card title="Detail Polis">
    <b-row v-if="policy.status == 3">
      <b-col md="6" xl="4">
        <b-form-group label="No Polis" label-for="no-policy">
          <b-form-input id="no-policy" readonly :value="policy.policy_number" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Tanggal Mulai" label-for="start-date">
          <b-form-input id="start-date" readonly :value="policy.start_date" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Tanggal Berkahir" label-for="end-date">
          <b-form-input id="end-date" readonly :value="policy.end_date" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" xl="4">
        <b-form-group label="KTP" label-for="id-card">
          <b-form-input
            id="id-card"
            v-model="policy.nik"
            v-if="policy.status == 0"
          />
          <b-form-input id="id-card" readonly :value="policy.nik" v-else />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Nama" label-for="name">
          <b-form-input
            id="name"
            v-model="policy.name"
            v-if="policy.status == 0"
          />
          <b-form-input id="name" readonly :value="policy.name" v-else />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" xl="4">
        <b-form-group label="Tempat Lahir" label-for="born-place">
          <b-form-input
            id="born-place"
            v-model="policy.birth_place"
            v-if="policy.status == 0"
          />
          <b-form-input
            id="born-place"
            readonly
            :value="policy.birth_place"
            v-else
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Tanggal Lahir" label-for="birthdate">
          <b-form-input
            id="birthdate"
            v-model="policy.birthdate"
            v-if="policy.status == 0"
          />
          <b-form-input
            id="birthdate"
            readonly
            :value="policy.birthdate"
            v-else
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Jenis Kelamin" label-for="gender">
          <b-form-input
            id="gender"
            v-model="policy.gender"
            v-if="policy.status == 0"
          />
          <b-form-input id="gender" readonly :value="policy.gender" v-else />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="No Telp" label-for="phone">
          <b-form-input
            id="phone"
            v-model="policy.phone"
            v-if="policy.status == 0"
          />
          <b-form-input id="phone" readonly :value="policy.phone" v-else />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Email" label-for="email">
          <b-form-input
            id="email"
            v-model="policy.email"
            v-if="policy.status == 0"
          />
          <b-form-input id="email" readonly :value="policy.email" v-else />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Pekerjaan" label-for="job">
          <b-form-input
            id="job"
            readonly
            v-model="policy.job"
            v-if="policy.status == 0"
          />
          <b-form-input id="job" readonly :value="policy.job" v-else />
        </b-form-group>
      </b-col>
    </b-row>
    <h4 class="my-2">Alamat Lengkap</h4>
    <b-row>
      <b-col xl="12">
        <b-form-group label="Alamat" label-for="address">
          <b-form-textarea
            id="address"
            v-model="policy.address"
            v-if="policy.status == 0"
          />
          <b-form-textarea
            id="address"
            readonly
            :value="policy.address"
            v-else
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Desa / Kelurahan" label-for="village">
          <b-form-input
            id="village"
            v-model="policy.village"
            v-if="policy.status == 0"
          />
          <b-form-input id="village" readonly :value="policy.village" v-else />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Kecamatan" label-for="district">
          <b-form-input
            id="email"
            v-model="policy.district"
            v-if="policy.status == 0"
          />
          <b-form-input id="email" readonly :value="policy.district" v-else />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Kota / Kabupaten" label-for="city">
          <b-form-input
            id="city"
            v-model="policy.city"
            v-if="policy.status == 0"
          />
          <b-form-input id="city" readonly :value="policy.city" v-else />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Provinsi" label-for="province">
          <b-form-input
            id="province"
            v-model="policy.province"
            v-if="policy.status == 0"
          />
          <b-form-input
            id="province"
            readonly
            :value="policy.province"
            v-else
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Kode Pos" label-for="postal-code">
          <b-form-input
            id="postal-code"
            v-model="policy.postal_code"
            v-if="policy.status == 0"
          />
          <b-form-input
            id="postal-code"
            readonly
            :value="policy.postal_code"
            v-else
          />
        </b-form-group>
      </b-col>
    </b-row>
    <h4 class="my-2">Dokumen Pendukung</h4>
    <b-row>
      <b-col md="6" xl="4">
        <b-form-group label="Nama Bank" label-for="bank-name">
          <b-form-input
            id="bank-name"
            v-model="policy.bank_name"
            v-if="policy.status == 0"
          />
          <b-form-input
            id="bank-name"
            readonly
            :value="policy.bank_name"
            v-else
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="No Rekening" label-for="bank-account-no">
          <b-form-input
            id="bank-account-no"
            v-model="policy.account_number"
            v-if="policy.status == 0"
          />
          <b-form-input
            id="bank-account-no"
            readonly
            :value="policy.account_number"
            v-else
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group
          label="Nama Pemilik Rekening"
          label-for="bank-account-name"
        >
          <b-form-input
            id="bank-account-name"
            v-model="policy.account_name"
            v-if="policy.status == 0"
          />
          <b-form-input
            id="bank-account-name"
            readonly
            :value="policy.account_name"
            v-else
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="KTP / Akta Lahir / KK" label-for="identity">
          <b-form-input
            id="identity"
            v-model="policy.account_name"
            v-if="policy.status == 0"
          />
          <b-form-input
            id="identity"
            readonly
            :value="policy.account_name"
            v-else
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-button
          block
          class="mt-2"
          variant="success"
          v-if="policy.status == 0"
          @click="onUpdate"
          >Update</b-button
        >
        <b-button block class="mt-2" variant="success" v-if="policy.status == 1"
          >Kirim</b-button
        >
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      policy: "",
    };
  },
  created() {
    this.$http
      .get("/policy/" + this.$route.params.id)
      .then((response) => {
        this.policy = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    onUpdate() {
      this.$http
        .post("/policy/" + this.$route.params.id + "/update", this.policy)
        .then((res) => {
          this.policy = res.data.data;

          this.$bvToast.toast("Update data berhasil", {
            title: "Berhasil",
            variant: "success",
            solid: true,
            autoHideDelay: 1000,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
            autoHideDelay: 1000,
          });
        });
    },
    onSend() {
      this.$http
        .post("/policy/send-data/" + this.$route.params.id)
        .then((res) => {
          this.$bvToast.toast("Update data berhasil", {
            title: "Berhasil",
            variant: "success",
            solid: true,
            autoHideDelay: 1000,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
            autoHideDelay: 1000,
          });
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
  },
};
</script>

<style>
</style>
